<template>
  <div v-if="warningExchange">
    <div class="rounded-xl bg-red-100 p-3 mb-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <ExclamationCircleIcon class="h-5 w-5 text-red-600" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-red-700">
            {{ $t('no_connect_exchange_alert') }}
          </p>
          <p class="mt-3 text-sm md:ml-6 md:mt-0">
            <router-link :to="`/exchange/${warningExchange.id}`" href="#" class="whitespace-nowrap font-medium text-red-700 hover:text-red-600">
              {{ $t('fix') }}
              <span aria-hidden="true"> &rarr;</span>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'

export default {
  props: [
    'exchanges'
  ],

  components: {
    ExclamationCircleIcon
  },

  mounted() {
    for (const item of this.exchanges) {
      if (!item.status_connected) {
        this.warningExchange = item;
      }
    }
  },

  data() {
    return {
      warningExchange: null,
    }
  }
}
</script>
<template>
  <TransitionRoot as="template" :show="openPopup">
    <Dialog class="relative z-50" @close="onClosePopup">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-950 bg-opacity-45 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-t-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full">
              <div v-if="transaction">
                <div class="mb-3 text-xl font-semibold">
                  Transaction ID: {{ transaction.id }}
                </div>
                <div class="grid grid-cols-2 border-t py-2 text-sm">
                  <div class="opacity-50">{{ $t('pay_method') }}</div>
                  <div>
                    <div class="flex items-center">
                      <img :src="`/img/pay/${transaction.payment_provider.payment_provider}.png`" class="w-5 h-5 rounded-full mr-2" />
                      {{ transaction.payment_provider.title }}
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 border-t py-2 text-sm">
                  <div class="opacity-50">{{ $t('type_payment') }}</div>
                  <div>{{ transaction.type }}</div>
                </div>
                <div class="grid grid-cols-2 border-t py-2 text-sm">
                  <div class="opacity-50">{{ $t('amount') }}</div>
                  <div>{{ transaction.amount }}</div>
                </div>
                <div class="grid grid-cols-2 border-t py-2 text-sm">
                  <div class="opacity-50">{{ $t('currency') }}</div>
                  <div>{{ transaction.currency }}</div>
                </div>
                <div class="grid grid-cols-2 border-t py-2 text-sm">
                  <div class="opacity-50">{{ $t('status') }}</div>
                  <div><TransactionStatus :transaction_status="transaction.status" /></div>
                </div>
                <div class="grid grid-cols-2 border-t py-2 text-sm">
                  <div class="opacity-50">{{ $t('date_created') }}</div>
                  <div>{{ transaction.created_at }}</div>
                </div>
                <div class="grid grid-cols-2 border-t py-2 text-sm">
                  <div class="opacity-50">{{ $t('date_updated') }}</div>
                  <div>{{ transaction.updated_at }}</div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import TransactionStatus from "@/components/elem/TransactionStatus.vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

export default {
  props: [
    'is_open',
    'transaction',
  ],

  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    TransactionStatus,
  },

  methods: {
    onClosePopup() {
      this.openPopup = false;
      this.$emit('update:is_open', false);
    }
  },

  data() {
    return {
      openPopup: this.is_open,
    };
  },

  watch: {
    is_open(newVal) {
      this.openPopup = newVal;
    }
  },

  mounted() {
    this.openPopup = this.is_open;
  },
};
</script>

<template>
  <div class="pt-4 px-4 pb-20">
    <h1 class="text-3xl font-bold mb-5">
      {{ $t('positions') }}
    </h1>

    <template v-if="loading">
      <div class="bg-white rounded-xl overflow-hidden">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <div class="mb-3">
        <div class="">
          <nav class="flex space-x-4" aria-label="Tabs">
            <a
              v-for="tab in tabs"
              :key="tab.key"
              href="javascript:void(0)"
              @click="onChangeTab(tab)"
              :class="[tab.key === this.activeTab ? 'bg-blue-100 text-blue-700' : 'text-gray-500 hover:text-gray-700', 'rounded-lg px-3 py-2 text-sm font-medium']"
            >{{ tab.name }}</a>
          </nav>
        </div>
      </div>

      <template v-if="activeTab === 'open'">
        <template v-if="orders.length">
          <div v-for="order in orders" :key="order.id" class="mb-5">
            <OrderItem :order="order" :is_edit="true" @close-position="onClosedPosition" />
          </div>
        </template>
        <template v-else>
          <div class="bg-white rounded-xl overflow-hidden px-4 py-4">
            <p class="text-center">{{ $t('no_active_positions') }}</p>
          </div>
        </template>
      </template>

      <template v-if="activeTab === 'history'">
        <template v-if="loadingHistory">
          <div class="bg-white rounded-xl overflow-hidden">
            <LoadingBlock />
          </div>
        </template>
        <template v-else>
          <template v-if="history.data.length">
            <div v-for="order in history.data" :key="order.id" class="mb-5">
              <OrderItem :order="order" />
            </div>
          </template>
          <template v-else>
            <div class="bg-white rounded-xl overflow-hidden px-4 py-4">
              <p class="text-center">{{ $t('no_history') }}</p>
            </div>
          </template>

          <template v-if="history.meta.last_page > this.historyParams.page">
            <button
              @click="onLoadHistoryMore"
              class="w-full bg-blue-100 text-blue-700 text-sm py-3 rounded-xl font-medium"
              :class="[loadingHistoryMore ? 'opacity-60 pointer-events-none' : '']"
            >
              <template v-if="loadingHistoryMore">
                <ButtonSpinnerBlue />
              </template>
              {{ $t('load_more') }}
            </button>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import OrderItem from "@/components/elem/OrderItem.vue";
import LoadingBlock from '@/components/LoadingBlock.vue'
import ButtonSpinnerBlue from "@/components/ButtonSpinnerBlue.vue";

export default {
  components: {
    LoadingBlock,
    OrderItem,
    ButtonSpinnerBlue
  },

  methods: {
    onChangeTab(tab) {
      this.activeTab = tab.key;

      if (this.activeTab === 'open') {
        this.loadOpenOrders();
      }

      if (this.activeTab === 'history') {
        this.loadHistoryOrder();
      }
    },

    onClosedPosition() {
      this.loadOpenOrders();
    },

    onLoadHistoryMore() {
      this.loadingHistoryMore = true;
    },

    loadOpenOrders() {
      console.log('Load orders...');

      this.$api.get('/orders/open').then((result) => {
        this.orders = result.data.data;
        this.loading = false
      }).catch(e => {
        console.log(e)
        this.error = e
      })
    },

    loadHistoryOrder() {
      console.log('Load history orders...');

      this.loadingHistory = true;

      this.$api.get('/orders', {
        params: this.historyParams
      }).then((result) => {
        console.log(result.data);
        this.history = result.data;
        this.loadingHistory = false;
      }).catch(e => {
        console.log(e)
        this.error = e
      })
    },
  },

  data() {
    return {
      loading: true,
      loadingHistory: true,
      loadingHistoryMore: false,
      orders: [],
      history: null,
      error: null,
      intervalId: null,
      activeTab: 'open',
      tabs: [
        { name: this.$t('open'), key: 'open' },
        { name: this.$t('history'), key: 'history' },
      ],
      historyParams: {
        per_page: 10,
        page: 1,
        statuses: [
          'FILLED',
          'CANCELED',
          'REJECTED',
          'EXPIRED',
        ],
      }
    }
  },

  mounted() {
    this.loadOpenOrders()
    this.intervalId = setInterval(this.loadOpenOrders, 5000)
  },

  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
}
</script>

<template>
  <div class="text-sm">
    <div v-for="block in content.blocks" :key="block.id">
      <p v-if="block.type === 'paragraph'" class="mt-2" v-html="block.data.text"></p>

      <div v-if="block.type === 'list'" class="mt-3">
        <ul v-if="block.data.style === 'unordered'" class="list-disc list-inside pl-3 space-y-2 text-gray-800">
          <li v-for="(item, index) in block.data.items" :key="index" v-html="item"></li>
        </ul>
        <ol v-else class="list-decimal list-inside pl-3 space-y-2 text-gray-800">
          <li v-for="(item, index) in block.data.items" :key="index" v-html="item"></li>
        </ol>
      </div>

      <component v-if="block.type === 'header'"
                 :is="'h' + block.data.level"
                 class="font-semibold mt-2"
                 :class="getHeaderClass(block.data.level)"
                 v-html="block.data.text">
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentRenderer',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  methods: {
    getHeaderClass(level) {
      switch (level) {
        case 1:
          return 'text-2xl';
        case 2:
          return 'text-xl';
        case 3:
          return 'text-lg';
        default:
          return 'text-base';
      }
    }
  }
}
</script>

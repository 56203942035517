import { createApp } from 'vue';
import App from './App.vue';
import createRouter from './router';
import store from './store';
import './assets/style.css';
import { createI18n } from 'vue-i18n';
import createApiClient from './api';
import { vMaska } from "maska/vue";
import VueGtag from 'vue-gtag-next';
import { createGtm } from '@gtm-support/vue-gtm';

const app = createApp(App);

let initData = '';
if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
    initData = window.Telegram.WebApp.initData
}

function generateSessionToken() {
    return [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
}

let sessionToken = sessionStorage.getItem('2fa_session_token');

if (!sessionToken) {
    sessionToken = generateSessionToken();
    sessionStorage.setItem('2fa_session_token', sessionToken);
}

let apiClient = createApiClient(initData);
app.config.globalProperties.$api = apiClient;

async function loadLocaleMessages() {
    try {
        const response = await apiClient.get('/languages');
        const messages = {};

        for (const item of response.data.data) {
            messages[item.language_code] = item.messages;
        }

        return messages;
    } catch (error) {
        console.error('Failed to load locales:', error);
        return {
            en: { welcome: 'Welcome' },
            ru: { welcome: 'Добро пожаловать' }
        };
    }
}

async function loadUser() {
    try {
        const response = await apiClient.get('/user');
        const user = response.data.data;

        localStorage.setItem('user_language', user.language_code);

        return user;
    } catch (error) {
        console.log(error)
    }
}

async function loadSettings() {
    try {
        const response = await apiClient.get('/settings');

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

async function initI18n(user) {
    const messages = await loadLocaleMessages();
    const i18n = createI18n({
        locale: user.language_code,
        fallbackLocale: 'en',
        messages: messages
    });

    return i18n;
}

(async () => {
    try {
        const user = await loadUser();
        apiClient = createApiClient(initData, user.language_code);
        app.config.globalProperties.$api = apiClient;

        const settings = await loadSettings();
        const i18n = await initI18n(user);
        const router = createRouter(user);

        app.config.globalProperties.$api = apiClient;
        app.config.globalProperties.$user = user;
        app.config.globalProperties.$settings = settings;
        app.directive("maska", vMaska);

        app.use(store);
        app.use(router);
        app.use(i18n);

        if (settings.ga_id) {
            app.use(VueGtag, {
                property: {
                    id: settings.ga_id
                }
            }, router);
        }

        if (settings.gtm_id) {
            app.use(
                createGtm({
                    id: settings.gtm_id,
                    defer: false,
                    compatibility: false,
                    debug: true,
                    loadScript: true,
                    vueRouter: router,
                }),
            );
        }

        app.mount('#app');
    } catch (error) {
        console.error('Initialization failed:', error);
    }
})();

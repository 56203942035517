<template>
  <div class="px-4 pt-5">
    <ol class="mb-8 flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
      <li class="flex md:w-full items-center text-blue-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
            <svg class="w-5 h-5 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            {{ $t('terms') }}
        </span>
      </li>
      <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
        <span class="flex items-center sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
          <template v-if="userAppStatus === 'CONTACT_DETAILS_INCOMPLETE'">
            <svg class="w-5 h-5 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
          </template>
          {{ $t('contacts') }}
        </span>
      </li>
    </ol>

    <template v-if="userAppStatus === 'PENDING_TERMS_ACCEPTANCE'">
      <div>
        <div class="text-3xl font-semibold">{{ $t('accept_terms') }}</div>
        <div class="text-sm mt-2">
          {{ $t('accept_terms_desc') }}
        </div>

        <dd class="mt-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-gray-100 rounded-xl border border-gray-200">
            <li v-for="document in documents" :key="document.id" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
              <div class="flex w-0 flex-1 items-center">
                <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <div class="ml-4 flex min-w-0 flex-1 gap-2">
                  <span class="truncate font-medium">{{ document.title }}</span>
                </div>
              </div>
              <div class="ml-3 flex-shrink-0">
                <a :href="document.file" target="_blank" class="font-medium text-blue-600 hover:text-blue-500">{{ $t('view') }}</a>
              </div>
            </li>
          </ul>
        </dd>

        <div class="mt-4 relative flex items-start">
          <div class="flex h-6 items-center">
            <input v-model="termsForm.accept" id="apply_terms" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600" />
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="apply_terms" class="font-medium text-gray-900">{{ $t('apply_terms') }}</label>
            <p id="comments-description" class="text-sm text-gray-500">{{ $t('apply_terms_desc') }}</p>
          </div>
        </div>

        <div class="mt-4">
          <button
            @click="onApplyTerms"
            type="button"
            class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            :class="[isFormProcessing || !termsForm.accept ? 'opacity-50 pointer-events-none' : '']"
          >
            <template v-if="isFormProcessing">
              <ButtonSpinner />
            </template>
            {{ $t('apply_terms') }}
          </button>
        </div>
      </div>
    </template>

    <template v-if="userAppStatus === 'CONTACT_DETAILS_INCOMPLETE'">
      <div>
        <div class="text-3xl font-semibold">{{ $t('fill_contacts') }}</div>

        <div class="mt-4">
          <div>
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">E-mail</label>
            <div class="mt-2">
              <input
                  v-model="contactForm.email"
                  id="email"
                  type="email"
                  class="block w-full rounded-xl border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
              <p v-if="emailError" class="text-red-500 text-sm mt-1">{{ emailError }}</p>
            </div>
          </div>
          <div class="mt-3">
            <PhoneInput v-model="contactForm.phone" />
            <p v-if="phoneError" class="text-red-500 text-sm mt-1">{{ phoneError }}</p>
          </div>
          <div class="mt-5">
            <button
                @click="updateContacts"
                type="button"
                class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                :class="[isFormProcessing ? 'opacity-50 pointer-events-none' : '']"
            >
              <template v-if="isFormProcessing">
                <ButtonSpinner />
              </template>
              {{ $t('save_contacts') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { PaperClipIcon } from "@heroicons/vue/24/outline";
import ButtonSpinner from "@/components/ButtonSpinner.vue";
import PhoneInput from "@/components/form/PhoneInput.vue";
import { validatePhoneNumber } from "@/helpers/validationHelper";

export default {
  components: {
    ButtonSpinner,
    PaperClipIcon,
    PhoneInput
  },

  data() {
    return {
      isFormProcessing: false,
      userAppStatus: this.$user.app_status,
      termsForm: {
        accept: false,
      },
      contactForm: {
        email: '',
        phone: ''
      },
      emailError: '',
      phoneError: '',

      documents: [],
    }
  },

  mounted() {
    this.loadDocuments();
    this.checkStatus();
  },

  watch: {
    userAppStatus(newStatus) {
      console.log(newStatus);
      this.checkStatus();
    }
  },

  methods: {
    checkStatus() {
      if (this.userAppStatus === 'COMPLETED') {
        this.$router.push({ name: 'setting' });
      }
    },

    onApplyTerms() {
      this.isFormProcessing = true;

      if (this.termsForm.accept) {
        this.$api.post('/user/apply-terms').then(result => {
          console.log('result', result);
          this.userAppStatus = 'CONTACT_DETAILS_INCOMPLETE';
          this.isFormProcessing = false;
        });
      }
    },

    loadDocuments() {
      this.$api.get('/documents?bot_id=1').then(result => {
        this.documents = result.data.data;
      });
    },

    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },

    updateContacts() {
      this.isFormProcessing = true;

      this.emailError = '';
      this.phoneError = '';

      if (!this.validateEmail(this.contactForm.email)) {
        this.emailError = this.$t('invalid_email');
        this.isFormProcessing = false;
        return;
      }

      if (!validatePhoneNumber(this.contactForm.phone)) {
        this.isFormProcessing = false;
        return;
      }

      this.$api.post('/user/update-contacts', this.contactForm).then(() => {
        window.location.href = '/setting';
      });
    }
  }
}
</script>


<template>
  <div class="p-4">
    <template v-if="loadingExchanges || loadingSignals || loading">
      <LoadingBlock />
    </template>
    <template v-else>
      <div class="flex items-center w-full border-b pb-3">
        <button @click="onOpenExchangeSelect" class="relative flex items-center mr-4">
          <TheExchangeTitle :exchange="exchange" />
          <ChevronDownIcon class="w-4 ml-2" />
        </button>
        <button @click="onOpenSignalSelect" class="flex items-center">
          <TheTickerTitle :ticker="ticker" />
          <div>
            <ChevronDownIcon class="w-4 ml-2" />
          </div>
        </button>
      </div>
      <div class="pt-3">
        <TradingViewChart :symbol="ticker.toUpperCase()" interval="60" theme="light" />
      </div>
      <div class="flex pt-3">
        <div class="w-3/5 pr-3">
          <div class="bg-white">
            <div class="mb-2">
              <button @click="onOpenChangeOrderType" class="text-sm flex justify-between items-center bg-gray-100 w-full py-1 px-2.5 rounded-lg">
                <template v-if="typeOrder === 'limit'">
                  Лимит-ордер
                </template>
                <template v-if="typeOrder === 'market'">
                  Маркет-ордер
                </template>
                <template v-if="typeOrder === 'tp_sl'">
                  TP/SL
                </template>
                <ChevronDownIcon class="w-4" />
              </button>
            </div>

            <div class="mb-2">
              <label class="text-xs mb-0.5">Цена (USDT)</label>
              <div class="relative">
                <span class="absolute right-2 top-1 text-sm opacity-30">USDT</span>
                <input v-model="orderForm.price" type="number" step="0.01" class="bg-gray-100 w-full py-1 px-2.5 rounded-lg text-sm border-0">
              </div>
            </div>

            <div class="mb-2">
              <label class="text-xs mb-0.5">Количество (BTC)</label>
              <div class="relative">
                <span class="absolute right-2 top-1 text-sm opacity-30">BTC</span>
                <input v-model="orderForm.amount" type="number" step="0.01" class="bg-gray-100 w-full py-1 px-2.5 rounded-lg text-sm border-0">
              </div>
            </div>

            <div class="mb-2 flex items-center justify-between text-sm">
              <div class="opacity-40">Доступно</div>
              <div>100 USDT</div>
            </div>

            <div class="mt-5">
              <div class="mb-3">
                <button class="text-sm w-full bg-green-500 py-2 text-white rounded-xl">
                  Купить (Лонг)
                </button>
              </div>
              <div>
                <button class="text-sm w-full bg-red-500 py-2 text-white rounded-xl">
                  Продать (Шорт)
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/5 text-xs">
          <div class="flex flex-col">
            <div class="flex justify-between mb-1 text-gray-400">
              <span>Цена</span>
              <span>Сумма</span>
            </div>
            <!-- Продажи (Asks) -->
            <div class="w-full relative">
              <ul>
                <li
                    v-for="(ask, index) in asks"
                    :key="index"
                    class="flex justify-between text-red-500 relative"
                >
                  <span class="relative z-10">{{ ask[0] }}</span>
                  <span class="relative z-10">{{ ask[1] }}</span>
                  <!-- Фон для визуализации объема -->
                  <div class="absolute left-0 top-0 bottom-0 bg-red-100 z-0 transition-all duration-500 ease-in-out"
                       :style="{ width: `${(ask[1] / maxVolume) * 100}%` }">
                  </div>
                </li>
              </ul>
            </div>
            <div
                class="text-sm py-1 font-semibold"
                :class="currentPriceChange === 'up' ? 'text-green-500' : 'text-red-500'"
            >
              {{ currentPrice }}
            </div>
            <!-- Покупки (Bids) -->
            <div class="w-full relative">
              <ul>
                <li
                    v-for="(bid, index) in bids"
                    :key="index"
                    class="flex justify-between text-green-500 relative"
                >
                  <span class="relative z-10">{{ bid[0] }}</span>
                  <span class="relative z-10">{{ bid[1] }}</span>
                  <!-- Фон для визуализации объема -->
                  <div class="absolute left-0 top-0 bottom-0 bg-green-100 z-0 transition-all duration-500 ease-in-out"
                       :style="{ width: `${(bid[1] / maxVolume) * 100}%` }">
                  </div>
                </li>
              </ul>
            </div>

            <!-- Соотношение покупок и продаж -->
            <div class="mt-3">
              <div class="flex justify-between mb-1 text-gray-500">
                <span class="text-xs text-green-500">{{ bidPercentage }}%</span>
                <span class="text-xs text-red-500">{{ askPercentage }}%</span>
              </div>
              <div class="relative w-full h-1.5 bg-gray-200 rounded-full overflow-hidden">
                <!-- Полоска покупок -->
                <div class="bg-green-500 h-full transition-all duration-500 ease-in-out" :style="{ width: `${bidPercentage}%` }"></div>
                <!-- Полоска продаж -->
                <div class="bg-red-500 h-full absolute top-0 right-0 transition-all duration-500 ease-in-out" :style="{ width: `${askPercentage}%` }"></div>
              </div>

              <!-- Текстовая статистика -->
              <div class="mt-1 text-center text-xs" v-if="statisticsMessage" :class="statisticsClass">
                {{ statisticsMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <div class="">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-4" aria-label="Tabs">
              <a
                  v-for="tab in tabs"
                  :key="tab.name"
                  href="#"
                  :class="[
                    tab.current ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                    'flex whitespace-nowrap border-b-2 px-1 py-3 text-sm font-medium'
                  ]"
              >
                {{ tab.name }}
                <span v-if="tab.count" :class="[
                    tab.current ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                    'ml-3 rounded-full px-2.5 py-0.5 text-xs font-medium'
                  ]">{{ tab.count }}</span>
              </a>
            </nav>
          </div>
        </div>
        <div>
          <template v-if="loadingOrders">
            <LoadingBlock />
          </template>
          <template v-else>
            <template v-if="orders.length">
              <div v-for="order in orders" :key="order.id">
                <OrderItemLine :order="order" />
              </div>
            </template>
            <template v-else>
              <div class="pt-5 text-sm">Нет открытых позиций.</div>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>

  <TransitionRoot as="template" :show="openChangeTypeOrder">
    <Dialog class="relative z-50" @close="openChangeTypeOrder = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-950 bg-opacity-45 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-t-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full">
              <div>
                <div class="mb-4">
                  <button @click="onChangeOrderType('limit')" class="flex items-center relative">
                    <div
                        class="rounded-xl p-2 relative"
                        :class="[typeOrder === 'limit' ? 'bg-blue-100 text-blue-700' : 'bg-gray-100']"
                    >
                      <template v-if="typeOrder === 'limit'">
                        <CheckCircleIcon class="w-5 h-5 absolute text-blue-600 -top-1 -right-1" />
                      </template>
                      <ArrowTrendingDownIcon class="w-8" />
                    </div>
                    <div class="text-left pl-3">
                      <div>Лимит-ордер</div>
                      <div class="text-xs opacity-60">
                        Покупка/продажа по указанной цене или лучше
                      </div>
                    </div>
                  </button>
                </div>
                <div class="mb-4">
                  <button @click="onChangeOrderType('market')" class="flex items-center relative">
                    <div
                        class="rounded-xl p-2 relative"
                        :class="[typeOrder === 'market' ? 'bg-blue-100 text-blue-700' : 'bg-gray-100']"
                    >
                      <template v-if="typeOrder === 'market'">
                        <CheckCircleIcon class="w-5 h-5 absolute text-blue-600 -top-1 -right-1" />
                      </template>
                      <ArrowTrendingUpIcon class="w-8" />
                    </div>
                    <div class="text-left pl-3">
                      <div>Маркет-ордер</div>
                      <div class="text-xs opacity-60">
                        Оперативная покупка/продажа по лучшей цене на текущем рынке
                      </div>
                    </div>
                  </button>
                </div>
                <div>
                  <button @click="onChangeOrderType('tp_sl')" class="flex items-center relative">
                    <div
                        class="rounded-xl p-2 relative"
                        :class="[typeOrder === 'tp_sl' ? 'bg-blue-100 text-blue-700' : 'bg-gray-100']"
                    >
                      <template v-if="typeOrder === 'tp_sl'">
                        <CheckCircleIcon class="w-5 h-5 absolute text-blue-600 -top-1 -right-1" />
                      </template>
                      <ArrowsUpDownIcon class="w-8" />
                    </div>
                    <div class="text-left pl-3">
                      <div>TP/SL</div>
                      <div class="text-xs opacity-60">
                        Авторазмещение ордера, когда рыночная цена достигает целевой цены
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="openExchangeSelect">
    <Dialog class="relative z-50" @close="openExchangeSelect = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-950 bg-opacity-45 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-t-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full">
              <div>
                <div class="mb-3">Выбрать аккаунт</div>
                <div v-for="exchangeItem in exchanges" :key="exchangeItem.id">
                  <button @click="onSelectExchange(exchangeItem)" class="w-full flex items-center">
                    <div
                        class="rounded-xl p-2 relative"
                        :class="[exchangeItem.id === exchange.id ? 'bg-blue-100 text-blue-700' : 'bg-gray-100']"
                    >
                      <template v-if="exchangeItem.id === exchange.id">
                        <CheckCircleIcon class="w-5 h-5 absolute text-blue-600 -top-1 -right-1" />
                      </template>
                      <img :src="`/img/${exchangeItem.exchange_slug}.png`" alt="" class="w-7" />
                    </div>
                    <div class="text-left pl-3">
                      <div>{{ capitalizeFirstLetter(exchangeItem.exchange_slug) }}</div>
                      <div class="text-xs opacity-60"></div>
                    </div>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="openSignalSelect">
    <Dialog class="relative z-50" @close="openSignalSelect = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-950 bg-opacity-45 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-t-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full">
              <div>
                <div class="mb-3">Выбрать пару</div>
                <div v-for="signalItem in signals" :key="signalItem.id" class="mb-3">
                  <button @click="onSelectTicker(signalItem)" class="flex items-center w-full">
                    <div>
                      <TheTickerTitle :ticker="signalItem.ticker" />
                    </div>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {capitalizeFirstLetter} from "@/helpers/stringHelper"
import { ChevronDownIcon, ArrowTrendingUpIcon, ArrowTrendingDownIcon, ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import { CheckCircleIcon } from "@heroicons/vue/20/solid";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import LoadingBlock from "@/components/LoadingBlock.vue";
import TheExchangeTitle from "@/components/elem/TheExchangeTitle.vue";
import TheTickerTitle from "@/components/elem/TheTickerTitle.vue";
import OrderItemLine from "@/components/elem/OrderItemLine.vue";
import TradingViewChart from "@/components/elem/TradingViewChart.vue";

export default {
  components: {
    LoadingBlock,
    ChevronDownIcon,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    ArrowTrendingUpIcon,
    ArrowTrendingDownIcon,
    ArrowsUpDownIcon,
    CheckCircleIcon,
    TheExchangeTitle,
    TheTickerTitle,
    OrderItemLine,
    TradingViewChart
  },

  computed: {
    maxVolume() {
      // Преобразуем значения в числа и находим максимальный объем
      const allVolumes = [...this.bids.map((b) => parseFloat(b[1])), ...this.asks.map((a) => parseFloat(a[1]))];
      return allVolumes.length ? Math.max(...allVolumes) : 1; // Убедимся, что allVolumes не пустой
    },
    totalBidVolume() {
      // Преобразуем и суммируем объемы в заявках на покупку
      return this.bids.length ? this.bids.reduce((sum, bid) => sum + parseFloat(bid[1]), 0) : 0;
    },
    totalAskVolume() {
      // Преобразуем и суммируем объемы в заявках на продажу
      return this.asks.length ? this.asks.reduce((sum, ask) => sum + parseFloat(ask[1]), 0) : 0;
    },
    totalVolume() {
      // Общий объем (сумма заявок на покупку и продажу)
      return this.totalBidVolume + this.totalAskVolume;
    },
    bidPercentage() {
      // Процент покупок
      return this.totalVolume > 0 ? ((this.totalBidVolume / this.totalVolume) * 100).toFixed(2) : 0;
    },
    askPercentage() {
      // Процент продаж
      return this.totalVolume > 0 ? ((this.totalAskVolume / this.totalVolume) * 100).toFixed(2) : 0;
    },
    statisticsMessage() {
      // Возвращаем текст в зависимости от соотношения покупок и продаж
      if (this.bidPercentage > this.askPercentage) {
        return "Чаще покупают сейчас";
      } else if (this.bidPercentage < this.askPercentage) {
        return "Чаще продают сейчас";
      } else {
        return "Покупки и продажи сбалансированы";
      }
    },
    statisticsClass() {
      // Определяем цвет текста в зависимости от покупок или продаж
      return this.bidPercentage > this.askPercentage ? 'text-green-500' : this.bidPercentage < this.askPercentage ? 'text-red-500' : '';
    }
  },

  methods: {
    onOpenSignalSelect() {
      this.openSignalSelect = true;
    },
    onOpenExchangeSelect() {
      this.openExchangeSelect = true;
    },
    onOpenChangeOrderType() {
      this.openChangeTypeOrder = true;
    },
    onCloseChangeOrderType() {
      this.openChangeTypeOrder = false;
    },
    onChangeOrderType(type) {
      this.typeOrder = type;
      this.onCloseChangeOrderType();
    },
    formatVolume(volume) {
      return volume < 0.0001 ? '0.0001' : volume.toFixed(4);
    },
    onSelectExchange(exchange) {
      window.location.href = `/trade-swap/${exchange.id}/${this.ticker}`;
    },
    onSelectTicker(signal) {
      window.location.href = `/trade-swap/${this.exchange.id}/${signal.ticker}`;
    },
    loadExchange(exchangeId) {
      console.log('exchangeId', exchangeId);

      this.$api.get('/exchanges/' + exchangeId).then((result) => {
        this.exchange = result.data.data;
        this.loading = false;
        console.log('exchange', this.exchange);

        this.loadOrders();
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
    loadExchanges() {
      this.$api.get('/exchanges').then((result) => {
        this.exchanges = result.data.data
        this.loadingExchanges = false

        console.log('exchanges', this.exchanges);
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
    loadSignals() {
      this.$api.get('/signals').then((result) => {
        this.signals = result.data.data
        this.loadingSignals = false

        console.log('signals', this.signals);
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
    loadOrders() {
      this.loadingOrders = true;

      this.$api.get('/orders', {
        params: {
          exchange_id: this.exchange.id,
          symbol: this.ticker,
          statuses: ['NEW'],
        }
      }).then((result) => {
        console.log('result', result.data.data);
        this.orders = result.data.data;
        this.loadingOrders = false;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
  },

  data() {
    return {
      loadingExchanges: true,
      loadingSignals: true,
      loadingOrders: true,
      loading: true,
      exchanges: [],
      signals: [],
      orders: [],
      exchange: null,
      openChangeTypeOrder: false,
      openExchangeSelect: false,
      openSignalSelect: false,
      typeOrder: 'limit',
      orderForm: {
        price: 0.00,
        amount: 0.00
      },
      ticker: 'btcusdt',
      currentPrice: 0,
      lastPrice: 0,
      bids: [],
      asks: [],
      currentPriceChange: 'neutral',
      tabs: [
        {
          name: 'Ордера',
          href: '#orders',
          count: 0,
          current: true
        },
        {
          name: 'История',
          href: '#history',
          count: 0,
          current: false
        },
      ],
      capitalizeFirstLetter
    };
  },

  mounted() {
    this.loadExchange(this.$route.params.exchange_id);
    this.loadExchanges();
    this.loadSignals();

    // Выводим тикер в консоль
    console.log(this.$route.params.ticker);

    // Присваиваем тикер из параметров
    this.ticker = this.$route.params.ticker;

    this.wsPrice = new WebSocket(`wss://stream.binance.com:9443/ws/${this.ticker.toLowerCase()}@trade`);

    this.wsPrice.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.p) {
        this.lastPrice = this.currentPrice;
        this.currentPrice = parseFloat(message.p).toFixed(4);
        this.currentPriceChange = this.currentPrice > this.lastPrice ? 'up' : this.currentPrice < this.lastPrice ? 'down' : 'neutral';
      }
    };

    this.wsOrderBook = new WebSocket(`wss://stream.binance.com:9443/ws/${this.ticker.toLowerCase()}@depth`);

    this.wsOrderBook.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.b && message.a) {
        this.bids = message.b.slice(0, 10).map(bid => [parseFloat(bid[0]).toFixed(4), this.formatVolume(parseFloat(bid[1]))]);
        this.asks = message.a.slice(0, 10).map(ask => [parseFloat(ask[0]).toFixed(4), this.formatVolume(parseFloat(ask[1]))]);
      }
    };
  },

  beforeUnmount() {
    if (this.wsPrice) this.wsPrice.close();
    if (this.wsOrderBook) this.wsOrderBook.close();
  }
};
</script>

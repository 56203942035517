<template>
  <div class="pt-4 px-4">
    <h1 class="text-3xl font-bold mb-5">{{ $t('stats') }}</h1>

    <template v-if="loading">
      <div class="bg-white flex flex-col rounded-lg p-3 pb-0 mb-3">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <template v-if="exchanges.length">
        <div>
          <div v-for="exchange in exchanges" :key="exchange.id" class="bg-white flex flex-col rounded-lg p-3 pb-0 mb-3">
            <div class="flex items-center border-b pb-3">
              <img :src="`/img/${exchange.exchange_slug}.png`" class="w-10 rounded-full mr-2">
              {{ exchange.exchange_slug.toUpperCase() }}
            </div>
            <div v-if="exchange.assets.length">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <th scope="col" class="py-2 pr-3 font-normal text-left text-sm text-gray-500 sm:pl-0">
                    {{ $t('coin') }}
                  </th>
                  <th scope="col" class="px-1 py-2 font-normal text-left text-sm text-gray-500">
                    {{ $t('available_for_trade') }}
                  </th>
                  <th scope="col" class="font-normal text-right text-sm text-gray-500 py-2">
                    {{ $t('balance') }}
                  </th>
                </thead>
                <tbody class="divide-y divide-gray-200">
                <tr v-for="(asset, index) in exchange.assets" :key="index">
                  <td class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    <div class="flex items-center">
                      <img :src="`/img/assets/${asset.symbol}.png`" class="w-6 mr-2" />
                      {{ asset.symbol }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm">
                    {{ asset.available_balance }}
                  </td>
                  <td class="relative whitespace-nowrap py-4 pl-1 text-right text-sm sm:pr-0">
                    {{ asset.balance }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center pt-10 flex flex-col items-center w-full">
          <SquaresPlusIcon class="w-10" />
          <h3 class="mt-2 text-sm font-semibold text-gray-900">
            {{ $t('no_connection_exchange') }}
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            {{ $t('you_need_setup_connection') }}
          </p>
          <div class="mt-6 w-full">
            <router-link to="/create-exchange" class="flex w-full items-center justify-center rounded-lg bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
              {{ $t('connect_exchange') }}
            </router-link>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import {
  SquaresPlusIcon
} from '@heroicons/vue/24/outline'

export default {
  components: {
    SquaresPlusIcon,
    LoadingBlock,
  },

  methods: {
    loadExchanges() {
      this.$api.get('/exchanges/assets').then((result) => {
        console.log(result.data)

        this.exchanges = result.data
        this.loading = false
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
  },

  data() {
    return {
      loading: true,
      exchanges: [],
      error: null,
      intervalId: null
    }
  },

  mounted() {
    this.loadExchanges()
  }
}
</script>

<template>
  <div class="flex flex-col items-center justify-between space-y-4 h-screen">
    <div class="flex flex-col items-center justify-center pt-10">
      <div class="text-6xl">
        🔐
      </div>
      <div class="font-semibold mt-2 text-2xl px-6 text-center">
        {{ $t('2fa_auth') }}
      </div>
      <div class="text-center px-6 mt-1">
        {{ $t('enter_digit_code') }}
      </div>
      <div class="flex space-x-2 mb-6 mt-4"
           :class="{ 'animate-shake': isInvalid }">
        <div
            v-for="(digit, index) in codeBlocks"
            :key="index"
            :class="['w-12 h-12 flex items-center justify-center text-3xl border rounded-xl', isInvalid ? 'border-red-500' : 'border-gray-300']"
        >
          {{ digit }}
        </div>
      </div>
    </div>

    <div class="pb-10">
      <VirtualKeyboard v-model="code" />
    </div>
  </div>
</template>

<script>
import VirtualKeyboard from '@/components/form/VirtualKeyboard.vue';

export default {
  components: {
    VirtualKeyboard,
  },
  data() {
    return {
      code: '',
      isInvalid: false,
    };
  },
  computed: {
    codeBlocks() {
      return this.code.split('').concat(Array(6).fill('')).slice(0, 6);
    }
  },
  watch: {
    code(newCode) {
      if (newCode.length === 6) {
        this.verify2FA();
      }
    }
  },
  methods: {
    async verify2FA() {
      try {
        const response = await this.$api.post('/2fa/verify', {
          the_2fa_code: this.code,
          the_2fa_session_token: sessionStorage.getItem('2fa_session_token')
        });

        console.log(response);
        window.location.href = '/';
      } catch (error) {
        console.log('Invalid 2FA code');
        if (navigator.vibrate) {
          navigator.vibrate(200);
        }

        this.isInvalid = true;
        setTimeout(() => {
          this.code = '';
          this.isInvalid = false;
        }, 1000);
      }
    }
  }
};
</script>

<style scoped>
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.animate-shake {
  animation: shake 0.3s ease-in-out;
}
</style>

<template>
  <div class="py-3">
    <div class="flex items-center justify-between">
      <div class="flex flex-col">
        <div :class="[
          order.position === 'long' ? 'text-green-500' : 'text-red-500',
          'text-xs'
        ]">
          {{order.position === 'long' ? 'Покупка' : 'Продажа'}}
        </div>
        <div class="text-sm font-semibold">
          {{ order.symbol }} x{{ order.leverage }}
        </div>
      </div>
      <div class="flex flex-col items-end">
        <div class="text-xs">PnL</div>
        <div :class="[parseFloat(order.pnl) > 0 ? 'text-emerald-500' : 'text-red-500', 'text-sm']">
          <template v-if="parseFloat(order.pnl) > 0">
            +{{ roundAmount(order.pnl) }}
          </template>
          <template v-else>
            {{ roundAmount(order.pnl) }}
          </template>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3">
      <div>
        <div>Размер</div>
      </div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import {roundAmount} from "@/helpers/numberHelper"
export default {
  props: [
    'order'
  ],

  data() {
    return {
      roundAmount
    }
  }
}
</script>
<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('signals') }}</h3>

    <div class="bg-white rounded-lg">
      <div class="px-3">
        <SwitchGroup
            as="div"
            :class="['flex items-center justify-between py-2']"
        >
          <span class="flex flex-grow flex-col">
            <SwitchLabel as="span" class="flex items-center justify-between text-sm font-medium leading-6 text-gray-900" passive>
              <div>
                <div class="flex items-center">
                  <div class="mr-1 flex items-center">
                    <span class="ml-1">{{ $t('stop_all_signals') }}</span>
                  </div>
                </div>
              </div>
            </SwitchLabel>
          </span>
          <Switch @click="toggleDisableAllSignals()" :class="[isAllSignalDisabled ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
            <span
                aria-hidden="true"
                :class="[
                  isAllSignalDisabled ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                ]"
            /></Switch>
        </SwitchGroup>
      </div>
    </div>

    <template v-if="groups.length">
      <div v-for="group in groups" :key="group.id" class="mt-3">
        <div class="text-gray-400 text-sm mb-1">{{ group.name }}</div>
        <div class="bg-white rounded-lg">
          <template v-if="group.signals.length">
            <div v-for="(signal, signalIndex) in group.signals" :key="signal.id" class="px-3">
              <SwitchGroup
                v-if="signal.status"
                as="div"
                :class="[
                  'flex items-center justify-between py-2',
                  signalIndex === (group.signals.length - 1) ? '' : 'border-b border-gray-100'
                ]"
              >
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="flex items-center justify-between text-sm font-medium leading-6 text-gray-900" passive>
                    <div>
                      <div class="flex items-center">
                        <router-link :to="`/signals/${signal.id}`" class="mr-1 flex items-center">
                          <QuestionMarkCircleIcon class="w-4 h-4 text-gray-500" />
                          <span class="ml-1">{{ signal.name }}</span>
                        </router-link>
                        <template v-if="signal.level_risk === 'low'">
                          <TheTooltip :id="`${signal.id}_${signal.max_leverage}`" :message="$t('max_leverage')">
                            <span class="text-xs text-green-custom">
                              x{{ signal.max_leverage }}
                            </span>
                          </TheTooltip>
                        </template>
                        <template v-if="signal.level_risk === 'medium'">
                          <TheTooltip :id="`${signal.id}_${signal.max_leverage}`" :message="$t('max_leverage')">
                            <span class="text-xs text-yellow-500">x{{ signal.max_leverage }}</span>
                          </TheTooltip>
                        </template>
                        <template v-if="signal.level_risk === 'high'">
                          <TheTooltip :id="`${signal.id}_${signal.max_leverage}`" :message="$t('max_leverage')">
                            <span class="text-xs text-red-600">
                              x{{ signal.max_leverage }}
                            </span>
                          </TheTooltip>
                        </template>
                      </div>

                      <div class="leading-none">
                        <template v-if="signal.level_risk === 'low'">
                          <TheTooltip :id="`${signal.id}_${signal.max_leverage}_lowrisk`" message="Торговая пара требует обеспечение на счету 1 к 6 относительно суммы в торгах.">
                            <span class="text-xs text-green-custom flex items-center">
                              <BoltIcon class="w-3 mr-1" /> {{ $t('low_risk') }}
                            </span>
                          </TheTooltip>
                        </template>
                        <template v-if="signal.level_risk === 'medium'">
                          <TheTooltip :id="`${signal.id}_${signal.max_leverage}_mediumrisk`" message="Торговая пара требует обеспечение на счету 1 к 8 относительно суммы в торгах.">
                            <span class="text-xs text-yellow-500 flex items-center">
                              <BoltIcon class="w-3 mr-1" /> {{ $t('medium_risk') }}
                            </span>
                          </TheTooltip>
                        </template>
                        <template v-if="signal.level_risk === 'high'">
                          <TheTooltip :id="`${signal.id}_${signal.max_leverage}_highrisk`" message="Торговая пара требует обеспечение на счету 1 к 12 относительно суммы в торгах.">
                            <span class="text-xs text-red-600 flex items-center">
                              <BoltIcon class="w-3 mr-1" /> {{ $t('high_risk') }}
                            </span>
                          </TheTooltip>
                        </template>
                      </div>
                    </div>
                    <div class="flex items-center mr-3">
                      <template v-for="(allow_exchange, aeIdx) in signal.params.allow_exchanges" :key="aeIdx">
                        <TheTooltip :id="`${allow_exchange}_${signal.id}`" :message="capitalizeFirstLetter(allow_exchange)">
                          <img :src="`/img/${allow_exchange}.png`" alt="" class="w-5 rounded-full ml-1" />
                        </TheTooltip>
                      </template>
                    </div>
                  </SwitchLabel>
                </span>
                <Switch @click="toggleSignal(signal.id)" :class="[signalEnabled.indexOf(signal.id) !== -1 ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
                  <span
                      aria-hidden="true"
                      :class="[
                        signalEnabled.indexOf(signal.id) !== -1 ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      ]"
                  />
                </Switch>
              </SwitchGroup>
            </div>
          </template>
          <template v-else>
            <div class="px-3 py-2">
              <p class="text-sm text-gray-400">{{ $t('no_signals_group') }}</p>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TheTooltip from "@/components/elem/TheTooltip.vue";
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'
import {BoltIcon, QuestionMarkCircleIcon} from "@heroicons/vue/16/solid";
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import {capitalizeFirstLetter} from "@/helpers/stringHelper"

export default {
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
    TheTooltip,
    BoltIcon,
    QuestionMarkCircleIcon
  },

  computed: {
    isAllSignalDisabled() {
      return this.signalEnabled.length === 0
    }
  },

  methods: {
    toggleDisableAllSignals() {
      if (this.signalEnabled.length > 0) {
        this.signalEnabled = [];
      } else {
        this.signalEnabled = this.signals.map(signal => signal.id);
      }

      this.updateUserSettings();
    },

    toggleSignal(id) {
      console.log('Change signal: ', id);

      const index = this.signalEnabled.indexOf(id);
      if (index === -1) {
        this.signalEnabled.push(id);
      } else {
        this.signalEnabled.splice(index, 1);
      }

      this.updateUserSettings()
    },

    updateUserSettings() {
      this.$api.post('/user/update-settings', {
        signals: this.signalEnabled,
      }).then((result) => {
        console.log('Settings updated.', result)
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadGroups() {
      this.$api.get('/groups').then((result) => {
        console.log(result.data.data)
        this.groups = result.data.data
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadUser() {
      this.$api.get('/user').then((result) => {
        this.user = result.data.data
        this.signalEnabled = this.user.settings.signals
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
  },

  data() {
    return {
      signalEnabled: [],
      signals: [],
      groups: [],
      error: null,
      capitalizeFirstLetter
    }
  },

  mounted() {
    this.loadUser();
    this.loadGroups();

    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>
<template>
  <button :data-tooltip-target="id" type="button">
    <slot />
  </button>

  <div :id="id" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
    {{ message }}
    <div class="tooltip-arrow" data-popper-arrow></div>
  </div>
</template>

<script>
import { initFlowbite } from 'flowbite'

export default {
  props: [
    'id',
    'message'
  ],

  mounted() {
    initFlowbite();
  }
}
</script>
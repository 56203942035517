
export function formattedAmount(amount) {
    return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'USD'
    }).format(amount);
}

export function roundAmount(amount, decimals = 2) {
    const numericAmount = parseFloat(amount);
    return parseFloat(numericAmount.toFixed(decimals));
}

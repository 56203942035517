export function addBackButton(router) {
    if (window.Telegram?.WebApp?.BackButton) {
        const backButton = window.Telegram.WebApp.BackButton;

        backButton.show();
        backButton.onClick(() => {
            router.back();
        });
    }
}

export function hideBackButton() {
    if (window.Telegram?.WebApp?.BackButton) {
        const backButton = window.Telegram.WebApp.BackButton;
        backButton.hide();
    }
}
<template>
  <div
    class="min-h-screen"
    :class="[
      'bg-white',
      $route.name === 'setting' ? 'bg-custom-gray' : '',
      $route.name === 'stats' ? 'bg-custom-gray' : '',
      $route.name === 'home' ? 'bg-custom-gray' : '',
      $route.name === 'notifications' ? 'bg-custom-gray' : '',
      $route.name === 'signals' ? 'bg-custom-gray' : '',
      $route.name === 'language' ? 'bg-custom-gray' : '',
      $route.name === 'orders' ? 'bg-custom-gray' : '',
      $route.name === 'refs' ? 'bg-custom-gray' : '',
      $route.name === 'faq' ? 'bg-custom-gray' : '',
      $route.name === 'tariff' ? 'bg-custom-gray' : '',
      $route.name === 'profile' ? 'bg-custom-gray' : '',
      $route.name === 'security' ? 'bg-custom-gray' : '',
      $route.name === 'show-signal' ? 'bg-custom-gray' : '',
      $route.name === 'ref-stats' ? 'bg-custom-gray' : '',
    ]"
  >
    <router-view></router-view>
  </div>
  <template v-if="isShowNavbar">
    <AppNavbar />
  </template>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
  },
  computed: {
    isShowNavbar() {
      return this.$user.app_status !== 'PENDING_TERMS_ACCEPTANCE'
          && this.$user.app_status !== 'CONTACT_DETAILS_INCOMPLETE'
          && this.$route.name !== 'two-factor-auth';
    }
  },
  data() {
    return {
      telegramReady: false,
    }
  },
  mounted() {
    if (window.Telegram && window.Telegram.WebApp) {
      this.telegramReady = true

      if (window.Telegram.WebApp.isVersionAtLeast('7.0')) {
        window.Telegram.WebApp.SettingsButton.show();

        window.Telegram.WebApp.SettingsButton.onClick(() => {
          console.log('Settings button clicked!');
          this.$router.push({ name: 'setting' });
        });
      }
    }
  },
}
</script>

<template>
  <div>
    <template v-if="showDetails">
      <dl class="mx-auto border-t border-gray-100 grid gap-px bg-gray-900/5 grid-cols-2">
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dt class="text-sm text-gray-500">
            {{ $t('started') }}
          </dt>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dd class="w-full text-sm font-semibold tracking-tight text-gray-900">
            {{ details.started }}
          </dd>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dt class="text-sm text-gray-500">
            {{ $t('term_apply') }}
          </dt>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dd class="w-full text-sm font-semibold tracking-tight text-gray-900">
            {{ details.term_apply }}
          </dd>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dt class="text-sm text-gray-500">
            {{ $t('contact_filled') }}
          </dt>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dd class="w-full text-sm font-semibold tracking-tight text-gray-900">
            {{ details.contact_filled }}
          </dd>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dt class="text-sm text-gray-500">
            {{ $t('tariff_payed') }}
          </dt>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dd class="w-full text-sm font-semibold tracking-tight text-gray-900">
            {{ details.tariff_payed }}
          </dd>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dt class="text-sm text-gray-500">
            Запустили торги
          </dt>
        </div>
        <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-1">
          <dd class="w-full text-sm font-semibold tracking-tight text-gray-900">
            {{ details.trade_started }}
          </dd>
        </div>
      </dl>
    </template>
    <button @click="onShowDetail" class="w-full border-t border-gray-100 text-sm text-gray-400 py-1 flex items-center justify-center">
      <template v-if="showDetails === false">
        <ChevronDownIcon class="w-4 mr-2" />
        {{ $t('detail') }}
      </template>
      <template v-if="showDetails === true">
        <ChevronUpIcon class="w-4 mr-2" />
        {{ $t('collapse') }}
      </template>
    </button>
  </div>
</template>
<script>
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/vue/24/outline";

export default {
  components: {ChevronDownIcon, ChevronUpIcon},
  props: [
    'details'
  ],

  methods: {
    onShowDetail() {
      this.showDetails = !this.showDetails;
    }
  },

  data() {
    return {
      showDetails: false,
    }
  }
};
</script>
<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('notifications') }}</h3>

    <div class="mt-3">
      <div class="bg-white rounded-lg">
        <div class="px-3">
          <SwitchGroup as="div" class="flex items-center justify-between border-b border-gray-100 py-2">
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>
                    {{ $t('order_opened') }}
                  </SwitchLabel>
                </span>
            <Switch @click="toggleNotification('order_opened')"
                    :class="[notifications.indexOf('order_opened') !== -1 ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
              <span aria-hidden="true"
                    :class="[notifications.indexOf('order_opened') !== -1 ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']"/>
            </Switch>
          </SwitchGroup>
          <SwitchGroup as="div" class="flex items-center justify-between border-b border-gray-100 py-2">
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>
                    {{ $t('breakeven_order') }}
                  </SwitchLabel>
                </span>
            <Switch @click="toggleNotification('breakeven_order')"
                    :class="[notifications.indexOf('breakeven_order') !== -1 ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
              <span aria-hidden="true"
                    :class="[notifications.indexOf('breakeven_order') !== -1 ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']"/>
            </Switch>
          </SwitchGroup>
          <SwitchGroup as="div" class="flex items-center justify-between border-b border-gray-100 py-2">
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>
                    {{ $t('closing_order') }}
                  </SwitchLabel>
                </span>
            <Switch @click="toggleNotification('closing_order')"
                    :class="[notifications.indexOf('closing_order') !== -1 ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
              <span aria-hidden="true"
                    :class="[notifications.indexOf('closing_order') !== -1 ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']"/>
            </Switch>
          </SwitchGroup>
          <SwitchGroup as="div" class="flex items-center justify-between py-2">
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="text-sm font-medium leading-6 text-gray-900" passive>
                    {{ $t('system_errors') }}
                  </SwitchLabel>
                </span>
            <Switch @click="toggleNotification('system_errors')"
                    :class="[notifications.indexOf('system_errors') !== -1 ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
              <span aria-hidden="true"
                    :class="[notifications.indexOf('system_errors') !== -1 ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']"/>
            </Switch>
          </SwitchGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addBackButton, hideBackButton } from '@/helpers/telegramHelper'
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'

export default {
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel
  },

  methods: {
    toggleNotification(key) {
      console.log('Change notification: ', key);

      const index = this.notifications.indexOf(key);
      if (index === -1) {
        this.notifications.push(key);
      } else {
        this.notifications.splice(index, 1);
      }

      this.updateUserSettings()
    },

    updateUserSettings() {
      this.$api.post('/user/update-settings', {
        notifications: this.notifications,
      }).then((result) => {
        console.log('Settings updated.', result)
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadUser() {
      this.$api.get('/user').then((result) => {
        this.user = result.data.data
        this.notifications = this.user.settings.notifications
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
  },

  data() {
    return {
      notifications: []
    }
  },

  mounted() {
    this.loadUser();

    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>
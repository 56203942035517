<template>
  <div class="tradingview-widget-container">
    <div id="tradingview_chart"></div>
  </div>
</template>

<script>
export default {
  props: {
    symbol: {
      type: String,
      default: 'BINANCE:BTCUSDT' // Символ для пары BTC/USDT на бирже Binance
    },
    interval: {
      type: String,
      default: '60' // Интервал по умолчанию (1 час)
    },
    theme: {
      type: String,
      default: 'light' // Тема графика (светлая или темная)
    }
  },
  mounted() {
    this.initTradingView();
  },
  methods: {
    initTradingView() {
      new window.TradingView.widget({
        symbol: this.symbol, // Символ с указанием биржи Binance
        interval: this.interval,
        container_id: "tradingview_chart",
        theme: this.theme,
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        allow_symbol_change: false, // Запрещаем смену символа
        hide_top_toolbar: true, // Убираем верхнюю панель инструментов
        hide_side_toolbar: true, // Убираем боковую панель
        details: false, // Отключаем отображение деталей
        width: "100%",
        height: "300", // Высота графика
        datafeed: "BINANCE" // Подключаем данные с Binance
      });
    }
  }
}
</script>

<style scoped>
.tradingview-widget-container {
  width: 100%;
}
</style>

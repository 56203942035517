<template>
  <div class="pt-4 pb-24 px-4">
    <template v-if="tariffInfo.status === 'no_tariff' || isChangeTariff">
      <h3 class="text-3xl font-bold mb-4">
        {{ isChangeTariff ? $t('change_tariff') : $t('select_tariff') }}
      </h3>
      <template v-if="isChangeTariff">
        <div class="rounded-md bg-blue-50 p-4 mb-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-blue-700">
                {{ $t('tariff_alert') }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-if="loading">
        <div class="bg-white rounded-lg">
          <LoadingBlock />
        </div>
      </template>
      <template v-else>
        <template v-if="exchanges.length === 0">
          <div class="mt-5 mb-5 bg-white rounded-xl px-3 py-3">
            <div class="text-center flex flex-col items-center w-full">
              <SquaresPlusIcon class="w-10" />
              <h3 class="mt-2 text-sm font-semibold text-gray-900">
                {{ $t('no_connection_exchange') }}
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ $t('you_need_setup_connection') }}
              </p>
              <div class="mt-6 w-full">
                <router-link to="/create-exchange" class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                  {{ $t('connect_exchange') }}
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <fieldset aria-label="Server size">
          <RadioGroup v-model="selectedTariff" class="space-y-2">
            <RadioGroupOption
              as="template"
              v-for="tariff in tariffs"
              :key="tariff.id"
              :value="tariff"
              :aria-label="tariff.id"
              :aria-description="`${tariff.id} per month`" v-slot="{ }"
              :class="[
                exchanges.length === 0 && parseInt(tariff.type_paid) === 2 ? 'pointer-events-none' : ''
              ]"
            >
              <div :class="[
                selectedTariff.id === tariff.id ? '' : '',
                exchanges.length === 0 && parseInt(tariff.type_paid) === 2 ? 'opacity-60' : '',
                'relative flex cursor-pointer rounded-xl bg-white px-4 py-2 outline-none flex justify-between'
              ]">
                <span class="flex items-center">
                  <span class="flex flex-col text-sm">
                    <span class="font-medium text-gray-900 flex items-center">
                      <template v-if="exchanges.length === 0 && parseInt(tariff.type_paid) === 2">
                        <LockClosedIcon class="w-4 mr-1" />
                      </template>
                      {{ tariff.title['en'] }}
                    </span>
                    <span class="text-gray-500">
                      <span class="block inline">{{ formattedAmount(tariff.balance[0]) }} - {{ formattedAmount(tariff.balance[1]) }}</span>
                    </span>
                  </span>
                </span>
                <span class="mt-2 flex w-24 text-sm mt-0 flex-col">
                <span class="font-medium text-gray-900 pr-6">
                  <template v-if="parseInt(tariff.type_paid) === 1">
                    {{ formattedAmount(tariff.price) }}
                  </template>
                  <template v-if="parseInt(tariff.type_paid) === 2">
                    {{ tariff.rollback_percentage }}%
                  </template>
                </span>
                <span class="text-gray-500">
                  {{ tariff.days }} {{ $t('days') }}
                </span>
              </span>
              <CheckCircleIcon :class="[selectedTariff.id !== tariff.id ? 'invisible' : '', 'h-5 w-5 text-blue-600 absolute top-2 right-2']" aria-hidden="true" />
              <span :class="[selectedTariff.id === tariff.id ? 'border' : 'border-2', selectedTariff.id === tariff.id ? 'border-blue-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
              </div>
            </RadioGroupOption>
          </RadioGroup>
        </fieldset>
        <template v-if="selectedTariff">
          <div class="mt-6 lg:col-start-3 lg:row-end-1">
            <h2 class="sr-only">{{ $t('info_about_selected_tariff') }}</h2>
            <div class="rounded-xl bg-white">
              <div class="flex flex-wrap">
                <div class="flex-auto pl-4 pt-4 pb-4">
                  <div class="text-base font-semibold leading-6 text-gray-900">
                    {{ selectedTariff.title['en'] }}
                    <template v-if="parseInt(selectedTariff.type_paid) === 1">
                      ${{ formattedAmount(selectedTariff.price) }}
                      <div class="text-gray-500 text-sm font-normal">{{ selectedTariff.days }} {{ $t('days') }}</div>
                    </template>
                    <template v-if="parseInt(selectedTariff.type_paid) === 2">
                      {{ selectedTariff.rollback_percentage }}% Rollback
                      <div class="text-gray-500 text-sm font-normal">{{ selectedTariff.days }} {{ $t('days') }}</div>
                    </template>
                  </div>
                </div>
                <div class="text-sm grid grid-cols-2 w-full flex-none gap-x-4 border-t border-gray-900/5 px-4 pt-4">
                  <div class="flex-none">
                    {{ $t('trade_balance') }}
                  </div>
                  <div class="text-sm text-gray-900">
                    {{ formattedAmount(selectedTariff.balance[0]) }} - {{ formattedAmount(selectedTariff.balance[1]) }}
                  </div>
                </div>
                <div class="mt-2 text-sm grid grid-cols-2 w-full flex-none gap-x-4 px-4 pb-4">
                  <div class="flex-none">
                    {{ $t('max_positions') }}
                  </div>
                  <div class="text-sm text-gray-500">
                    {{ selectedTariff.max_positions }}
                  </div>
                </div>
                <div v-if="selectedTariff.desc[this.$user.language_code]" class="text-sm flex w-full flex-none gap-x-4 px-4 pb-4">
                  {{ selectedTariff.desc[this.$user.language_code] }}
                </div>

                <div v-if="selectedTariff.type_paid == 1" class="w-full border-t border-gray-900/5 px-4 pt-2 pb-4">
                  <Listbox as="div" v-model="selectedMonths">
                    <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">
                      Количество месяцев
                    </ListboxLabel>
                    <div class="relative mt-2">
                      <ListboxButton class="relative w-full cursor-default rounded-xl bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                          <span class="block truncate">
                            {{ selectedMonths ? selectedMonths.label : ''}}
                          </span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
                      </ListboxButton>

                      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute bottom-full z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <ListboxOption as="template" v-for="(tariffMonth, tariffMonthIndex) in months" :key="tariffMonthIndex" :value="tariffMonth" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-blue-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                              <div class="flex items-center">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                  {{ tariffMonth.label }}
                                </span>
                                <template v-if="tariffMonth.conditions">
                                  <div class="flex items-center rounded-full bg-orange-50 text-orange-500 text-sm px-2 py-0.5 ml-2">
                                    <FireIcon class="w-4 h-4 mr-2" /> {{ formattedAmount(tariffMonth.conditions.price) }}
                                  </div>
                                </template>
                              </div>
                              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>
                <div v-if="selectedTariff.type_paid == 1" class="w-full flex items-center justify-between border-t border-gray-900/5 px-4 pt-2 pb-2">
                  <div class="text-sm opacity-70">К оплате</div>
                  <div class="font-semibold text-xl">{{ formattedAmount(total) }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="mt-6 w-full">
          <a
              @click="onSelectTariff"
              href="javascript:void(0)"
              :class="[formProcessing ? 'opacity-50 pointer-events-none' : '']"
              class="flex w-full items-center justify-center rounded-xl bg-blue-600 px-3 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            <template v-if="formProcessing">
              <ButtonSpinner />
            </template>
            {{ isChangeTariff ? $t('change_tariff') : $t('select_tariff') }}
          </a>

          <div v-if="isChangeTariff" class="mt-3 grid grid-cols-1">
            <button @click="isChangeTariff = false" class="flex w-full items-center justify-center rounded-xl bg-gray-200 text-sm px-3 py-3 font-semibold hover:bg-gray-300 focus-visible:outline">
              {{ $t('cancel') }}
            </button>
          </div>
        </div>

        <template v-if="formErrorMessage !== ''">
          <div class="rounded-md bg-red-50 p-4 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">{{ $t('error') }}</h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul role="list" class="list-disc space-y-1 pl-5">
                    <li>{{ formErrorMessage }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
    <template v-if="tariffInfo.status === 'active' && !isChangeTariff">
      <h3 class="text-3xl font-bold mb-5">
        {{ $t('your_tariff') }}
      </h3>
      <div class="bg-white rounded-xl mb-3 flex items-center justify-between px-3 py-2">
        <div class="w-full">
          <dl class="divide-y divide-gray-100">
            <div class="py-3 grid grid-cols-2">
              <dt class="text-sm leading-6 text-gray-500">{{ $t('tariff') }}</dt>
              <dd class="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ tariffInfo.tariff.title['en'] }}
              </dd>
            </div>
            <div class="py-3 grid grid-cols-2">
              <dt class="text-sm leading-6 text-gray-500">{{ $t('status') }}</dt>
              <dd class="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <template v-if="tariffInfo.status === 'active'">
                  <span class="bg-green-500 text-sm px-3 py-0.5 rounded-full text-white">{{ $t('active') }}</span>
                </template>
                <template v-if="tariffInfo.status === 'expired'">
                  <span class="bg-red-500 text-sm px-3 py-0.5 rounded-full text-white">{{ $t('time_expired') }}</span>
                </template>
                <template v-if="tariffInfo.status === 'no_tariff'">
                  <span class="bg-gray-200 text-sm px-3 py-0.5 rounded-full">{{ $t('tariff_not_select') }}</span>
                </template>
              </dd>
            </div>
            <div class="py-3 grid grid-cols-2">
              <dt class="text-sm leading-6 text-gray-500">{{ $t('paid_to') }}</dt>
              <dd class="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {{ tariffInfo.expires_at_formatted }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="mt-5 grid grid-cols-1">
        <button @click="onShowChangeTariff" class="flex w-full items-center justify-center rounded-xl bg-gray-200 text-sm px-3 py-3 font-semibold hover:bg-gray-300 focus-visible:outline">
          {{ $t('change_tariff') }}
        </button>
      </div>

      <div class="mt-8">
        <template v-if="loadingTransactions">
          <div class="bg-white rounded-lg">
            <LoadingBlock />
          </div>
        </template>
        <template v-else>
          <div class="font-semibold mb-3">{{ $t('transactions') }}</div>
          <template v-if="transactions.length > 0">
            <button
                v-for="transaction in transactions"
                :key="transaction.id"
                class="bg-white w-full rounded-xl px-3 py-2 flex items-center justify-between mb-3"
                @click="onOpenDetailTransaction(transaction)"
            >
              <div class="flex items-center">
                <div>
                  <img :src="`/img/pay/${transaction.payment_provider.payment_provider}.png`" class="w-10 h-10 rounded-full" />
                </div>
                <div class="pl-2 text-left">
                  {{ $t('pay_tariff') }}
                  <div class="opacity-50 text-sm">{{ transaction.payment_provider.title }}</div>
                </div>
              </div>
              <div class="flex flex-col items-end">
                {{ formattedAmount(transaction.amount) }}
                <TransactionStatus :transaction_status="transaction.status" />
              </div>
            </button>
          </template>
          <template v-else>
            <div class="bg-white rounded-xl overflow-hidden px-4 py-4">
              <p class="text-center">{{ $t('no_transactions') }}</p>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>

  <TransitionRoot as="template" :show="openSelectPaymentProvider">
    <Dialog class="relative z-50" @close="openSelectPaymentProvider = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-t-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full">
              <div>
                <h2 class="font-bold text-2xl text-gray-900">{{ $t('payment_method') }}</h2>
                <div>
                  <template v-if="loadingPaymentProviders">
                    <LoadingBlock />
                  </template>
                  <template v-else>
                    <a
                      @click="onPayNow(paymentProvider)"
                      v-for="paymentProvider in paymentProviders"
                      :key="paymentProvider.id"
                      href="javascript:void(0)"
                      class="flex items-center border p-2 rounded-lg mt-4"
                    >
                      <div class="pr-3">
                        <img :src="`/img/pay/${paymentProvider.payment_provider}.png`" class="w-10 h-10 rounded-lg" />
                      </div>
                      <div>
                        <div>{{ paymentProvider.title }}</div>
                      </div>
                    </a>
                  </template>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <InfoTransactionPopup
    :is_open="isOpenDetailTransaction"
    :transaction="showDetailTransaction"
    @update:is_open="onCloseDetailTransaction"
  />
</template>

<script>
import TransactionStatus from "@/components/elem/TransactionStatus.vue";
import LoadingBlock from '@/components/LoadingBlock.vue'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import {CheckCircleIcon, XCircleIcon, CheckIcon, ChevronUpDownIcon, FireIcon, InformationCircleIcon, LockClosedIcon} from '@heroicons/vue/20/solid'
import ButtonSpinner from '@/components/ButtonSpinner.vue'
import {SquaresPlusIcon} from "@heroicons/vue/24/outline";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import InfoTransactionPopup from "@/components/elem/InfoTransactionPopup.vue";
import {formattedAmount} from "@/helpers/numberHelper";

export default {
  components: {
    TransactionStatus,
    XCircleIcon,
    SquaresPlusIcon,
    LoadingBlock,
    RadioGroup,
    RadioGroupOption,
    CheckCircleIcon,
    ButtonSpinner,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    FireIcon,
    InformationCircleIcon,
    LockClosedIcon,
    InfoTransactionPopup
  },

  computed: {
    tariffInfo() {
      return this.$user.tariff;
    },

    total() {
      if (!this.selectedTariff) {
        return 0.00;
      }

      for (const condition of this.selectedTariff.conditions) {
        if (condition && parseInt(condition.months) === parseInt(this.selectedMonths.value)) {
          return condition.price;
        }
      }

      return this.selectedTariff.price * this.selectedMonths.value;
    },

    months() {
      const res = [];
      let conditions = null;

      if (this.selectedTariff && this.selectedTariff.conditions) {
        for (const tariffMonth of this.tariffMonths) {
          conditions = null;

          for (const condition of this.selectedTariff.conditions) {
            if (condition && parseInt(condition.months) === parseInt(tariffMonth.value)) {
              conditions = condition;
            }
          }

          res.push({
            label: tariffMonth.label,
            value: tariffMonth.value,
            conditions: conditions,
          });
        }
      } else {
        for (const tariffMonth of this.tariffMonths) {
          res.push({
            label: tariffMonth.label,
            value: tariffMonth.value,
            conditions: null,
          });
        }
      }

      return res;
    }
  },

  methods: {
    onOpenDetailTransaction(transaction) {
      this.isOpenDetailTransaction = true;
      this.showDetailTransaction = transaction;
    },

    onCloseDetailTransaction() {
      this.isOpenDetailTransaction = false;
      this.showDetailTransaction = null;
    },

    onSelectTariff() {
      if (parseInt(this.selectedTariff.type_paid) === 1) {
        this.openSelectPaymentProvider = true;
        this.loadPaymentProviders();
      } else {
        this.onChangeTariff();
      }
    },

    onPayNow(paymentProvider) {
      this.form.payment_provider_id = paymentProvider.id

      this.onChangeTariff();
    },

    onShowChangeTariff() {
      this.isChangeTariff = true;
    },

    onChangeTariff() {
      this.formErrorMessage = ''
      this.formProcessing = true

      const tariffId = this.selectedTariff.id

      this.form.months = this.selectedMonths.value;

      this.$api.post(`/tariffs/${tariffId}/select`, this.form).then((result) => {
        if (parseInt(result.data.status) === 1) {
          window.location.href = result.data.data.url
        } else {
          this.formErrorMessage = result.data.message
        }

        this.formProcessing = false
        console.log(result.data)
      }).catch(e => {
        alert(e.message)
        this.error = e
        this.formProcessing = false
      })
    },

    loadExchanges() {
      this.$api.get('/exchanges').then((result) => {
        console.log(result)
        this.exchanges = result.data.data
        this.loading = false
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadTariffs() {
      this.$api.get('/tariffs?bot_id=1').then((result) => {
        console.log(result.data.data)
        this.tariffs = result.data.data

        this.selectedTariff = this.tariffs[0]
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadPaymentProviders() {
      this.$api.get('/payment-providers?bot_id=1').then((result) => {
        console.log(result.data.data)
        this.paymentProviders = result.data.data
        this.loadingPaymentProviders = false
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadTransactions() {
      this.loadingTransactions = true;

      this.$api.get('/transactions').then((result) => {
        console.log('transactions', result.data)
        this.transactions = result.data.data;
        this.loadingTransactions = false;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    }
  },

  data() {
    return {
      loading: true,
      loadingPaymentProviders: true,
      loadingTransactions: true,
      error: null,
      selectedExchange: null,
      selectedTariff: null,

      tariffs: [],
      exchanges: [],
      paymentProviders: [],
      transactions: [],

      tariffMonths: [
        {
          label: '1 ' + this.$t('month'),
          value: 1,
        },
        {
          label: '2 '  + this.$t('months'),
          value: 2,
        },
        {
          label: '3 ' + this.$t('months'),
          value: 3,
        },
        {
          label: '4 ' + this.$t('months'),
          value: 4,
        },
        {
          label: '5 ' + this.$t('t_months'),
          value: 5,
        },
        {
          label: '6 ' + this.$t('t_months'),
          value: 6,
        },
        {
          label: '7 ' + this.$t('t_months'),
          value: 7,
        },
        {
          label: '8 ' + this.$t('t_months'),
          value: 8,
        },
        {
          label: '9 ' + this.$t('t_months'),
          value: 9,
        },
        {
          label: '10 ' + this.$t('t_months'),
          value: 10,
        },
        {
          label: '11 ' + this.$t('t_months'),
          value: 11,
        },
        {
          label: '12 ' + this.$t('t_months'),
          value: 12,
        }
      ],

      selectedMonths: null,

      form: {
        tariff_id: null,
        payment_provider_id: null,
        months: 1,
      },

      isOpenDetailTransaction: false,
      showDetailTransaction: null,

      formProcessing: false,
      formErrorMessage: '',

      openSelectPaymentProvider: false,

      isChangeTariff: false,

      formattedAmount
    }
  },

  mounted() {
    this.selectedMonths = this.months[0];

    this.loadExchanges();
    this.loadTariffs();
    this.loadTransactions();
  }
}
</script>
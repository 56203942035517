<template>
  <div class="flex items-center">
    <img :src="`/img/${exchange.exchange_slug}.png`" alt="" class="w-5 rounded-full mr-2" />
    {{ capitalizeFirstLetter(exchange.exchange_slug) }}
  </div>
</template>

<script>
import {capitalizeFirstLetter} from "@/helpers/stringHelper"
export default {
  props: ['exchange'],

  data() {
    return {
      capitalizeFirstLetter
    }
  }
}
</script>
<template>
  <div class="relative flex items-center">
    <CryptoIcon :symbol="ticker" />
    <img src="../../../node_modules/cryptocurrency-icons/svg/color/usdt.svg" class="w-5 relative right-2">
    <span class="">{{ ticker.toUpperCase() }}</span>
  </div>
</template>

<script>
import CryptoIcon from "@/components/icons/CryptoIcon.vue";

export default {
  components: {CryptoIcon},
  props: ['ticker']
}
</script>
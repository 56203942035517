<template>
  <div class="pt-4 px-4 pb-20">
    <h1 class="text-3xl font-bold mb-5">
      {{ $t('dashboard') }}
    </h1>

    <div>
      <div class="block">
        <nav class="flex space-x-4 mb-5" aria-label="Tabs">
          <a
              v-for="tab in tabs"
              :key="tab.key"
              href="javascript:void(0)"
              :class="[
              tab.key === activePeriod ? 'bg-blue-100 text-blue-700' : 'text-gray-500 hover:text-gray-700',
              'rounded-md px-3 py-2 text-sm font-medium'
            ]"
              @click="onChangePeriod(tab.key)"
          >{{ tab.name }}</a>
        </nav>
      </div>

      <div class="bg-white rounded-xl overflow-hidden">
        <template v-if="loading">
          <LoadingBlock />
        </template>
        <template v-else>
          <template v-if="chartLoading">
            <LoadingBlock />
          </template>
          <template v-else>
            <TheChart :chart_data="chartData" />
          </template>
          <dl class="mx-auto border-t border-gray-100 grid gap-px bg-gray-900/5 grid-cols-2">
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-3">
              <dt class="text-sm font-medium text-gray-500">PnL (USDT)</dt>
              <dd :class="[dashboard.percent_pnl < 0 ? 'text-rose-600' : 'text-green-600', 'text-xs font-medium']">
                {{ roundAmount(dashboard.percent_pnl) }}%
              </dd>
              <dd
                class="w-full flex-none text-xl font-medium tracking-tight"
                :class="[dashboard.pnl < 0 ? 'text-rose-600' : 'text-green-600']"
              >
                {{ roundAmount(dashboard.pnl)}}
              </dd>
            </div>
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-3">
              <dt class="text-sm font-medium text-gray-500">
                {{ $t('positions') }}
              </dt>
              <dd class="w-full flex-none text-xl font-medium tracking-tight text-gray-900">
                {{ dashboard.positions || 0 }}
              </dd>
            </div>
          </dl>
        </template>
      </div>

      <div class="mt-4">
        <div class="mb-1 flex items-center justify-between">
          {{ $t('referrals') }}
          <router-link to="/ref-stats" class="opacity-50 flex items-center text-sm">
            {{ $t('read_more') }}
            <ChevronRightIcon class="w-4 ml-1" />
          </router-link>
        </div>
        <div class="bg-white rounded-xl overflow-hidden">
          <template v-if="loading">
            <LoadingBlock />
          </template>
          <template v-else>
            <dl class="mx-auto border-t border-gray-100 grid gap-px bg-gray-900/5 grid-cols-2">
              <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-3">
                <dt class="text-sm font-medium text-gray-500">
                  {{ $t('income') }}
                </dt>
                <dd :class="[dashboard.referral_income_up_percent < 0 ? 'text-rose-600' : 'text-green-600', 'text-xs font-medium']">
                  {{ dashboard.referral_income_up_percent }}%
                </dd>
                <dd
                    class="w-full flex-none text-xl font-medium tracking-tight"
                    :class="[dashboard.referral_income < 0 ? 'text-rose-600' : 'text-green-600']"
                >
                  ${{ dashboard.referral_income }}
                </dd>
              </div>
              <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-3 py-3">
                <dt class="text-sm font-medium text-gray-500">{{ $t('referrals') }}</dt>
                <dd class="w-full flex-none text-xl font-medium tracking-tight text-gray-900">
                  {{ dashboard.referrals }}
                </dd>
              </div>
            </dl>
          </template>
        </div>
      </div>

      <div class="mt-6">
        <div class="mb-3 bg-white rounded-xl overflow-hidden">
          <template v-if="faqFeatures">
            <TheFaq :items="faqFeatures.data" />
          </template>
          <template v-else>
            <LoadingBlock />
          </template>
        </div>
        <router-link v-if="faqFeatures" to="/faq" type="button" class="w-full flex items-center justify-center rounded-xl bg-gray-50 px-3.5 py-2.5 text-gray-600">
          {{ $t('find_more') }} <ArrowRightIcon class="ml-2 w-5" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import TheChart from "@/components/elem/TheChart.vue";
import TheFaq from "@/components/TheFaq.vue";
import {ArrowRightIcon, ChevronRightIcon} from "@heroicons/vue/24/outline";
import {roundAmount} from '@/helpers/numberHelper'

export default {
  components: {
    LoadingBlock,
    TheChart,
    TheFaq,
    ArrowRightIcon,
    ChevronRightIcon
  },

  data() {
    return {
      loading: true,
      chartLoading: true,
      error: null,

      faqFeatures: null,
      dashboard: null,
      chartData: null,

      activePeriod: '7days',
      tabs: [
        {
          name: this.$t('7days'),
          key: '7days',
        },
        {
          name: this.$t('14days'),
          key: '14days',
        },
        {
          name: this.$t('30days'),
          key: 'month',
        },
      ],
      roundAmount
    }
  },

  methods: {
    onChangePeriod(period) {
      this.activePeriod = period;

      this.fetchData();
    },

    fetchData() {
      this.loading = true;
      console.log('Fetching data for period:', this.activePeriod);

      this.$api.get('/dashboard', {
        params: {
          period: this.activePeriod
        }
      }).then(result => {
        console.log('res', result.data)
        this.dashboard = result.data;
        this.loading = false

        this.fetchChart();
      })
    },

    fetchChart() {
      this.chartLoading = true;

      this.$api.get('/dashboard/chart', {
        params: {
          period: this.activePeriod
        }
      }).then(result => {
        console.log('res', result.data)
        this.chartData = result.data;
        this.chartLoading = false;
      })
    },

    onLoadFaqFeatures() {
      this.$api.get('/faq/features').then(result => {
        console.log('res', result.data)
        this.faqFeatures = result.data;
      })
    }
  },

  mounted() {
    this.fetchData();
    this.onLoadFaqFeatures();
  }
}
</script>

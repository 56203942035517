<template>
  <div>
    <apexchart type="area" height="120" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
  props: [
    'chart_data'
  ],

  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [{
        name: 'Profit',
        data: this.chart_data.pnl
      }],
      chartOptions: {
        chart: {
          height: 120,
          type: 'area', // Используем 'area' для добавления градиента под линией
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false  // Отключаем тулбар с кнопками экспорта
          },
          parentHeightOffset: 0,
          offsetX: 0,
          offsetY: 0,
        },
        stroke: {
          curve: 'smooth',
          width: 2,  // Устанавливаем четкую линию шириной 2
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 100]
          }
        },
        // Цвет линии и маркеров будет динамически изменен
        colors: this.getTotalPnl() >= 0 ? ['#0a9367'] : ['#ff4560'],
        markers: {
          size: 3,  // Размер точек
          colors: this.getTotalPnl() >= 0 ? ['#0a9367'] : ['#ff4560'],  // Цвет точек
          strokeColors: this.getTotalPnl() >= 0 ? ['#0a9367'] : ['#ff4560'],  // Цвет обводки точек
          strokeWidth: 2,
          hover: {
            size: 7
          }
        },
        xaxis: {
          categories: this.chart_data.dates,
          title: {
            text: undefined
          },
          labels: {
            show: false // Отключаем метки на оси X
          },
          axisBorder: {
            show: false // Убираем линию оси X
          },
          axisTicks: {
            show: false // Убираем тики оси X
          }
        },
        yaxis: {
          show: false,  // Полностью отключаем ось Y, включая метки
          title: {
            text: undefined
          },
          labels: {
            show: false  // Отключаем метки на оси Y
          }
        },
        grid: {
          show: false, // Отключаем сетку
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        dataLabels: {
          enabled: false  // Убираем метки данных
        },
        plotOptions: {
          area: {
            colors: {
              ranges: [
                {
                  from: -Infinity,
                  to: 0,
                  color: '#ff4560'
                },
                {
                  from: 0,
                  to: Infinity,
                  color: '#0a9367'  // Зеленый для положительных значений
                }
              ]
            }
          }
        }
      }
    }
  },
  methods: {
    getTotalPnl() {
      // Рассчитываем общую сумму PNL
      return this.chart_data.pnl.reduce((acc, value) => acc + value, 0);
    }
  }
}
</script>

<style scoped>
/* Добавьте свои стили здесь, если нужно */
</style>

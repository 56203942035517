<template>
  <div class="keyboard flex flex-col items-center space-y-4">
    <!--<div class="flex space-x-2 mb-6">
      <div v-for="n in 6" :key="n" class="w-4 h-4 rounded-full border-2 border-gray-300"
           :class="{'bg-gray-800': modelValue.length >= n}">
      </div>
    </div> -->

    <div class="key-row grid grid-cols-3 gap-4">
      <button
          v-for="key in keys"
          :key="key"
          @click="addDigit(key)"
          class="text-black text-3xl w-16 h-16 flex items-center justify-center rounded-full focus:outline-none active:bg-gray-50"
      >
        {{ key }}
      </button>
    </div>
    <div class="key-row grid grid-cols-3 gap-4 mt-4">
      <div></div>
      <button
          @click="addDigit('0')"
          class="text-black text-3xl w-16 h-16 flex items-center justify-center rounded-full focus:outline-none active:bg-gray-50"
      >
        0
      </button>
      <button
          @click="removeDigit"
          class="text-black text-3xl w-16 h-16 flex items-center justify-center rounded-full focus:outline-none active:bg-red-50"
      >
        <BackspaceIcon class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<script>
import {BackspaceIcon} from "@heroicons/vue/24/outline";

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  components: {
    BackspaceIcon
  },
  data() {
    return {
      keys: ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    };
  },
  methods: {
    addDigit(digit) {
      if (this.modelValue.length < 6) {
        this.$emit('update:modelValue', this.modelValue + digit);
      }
    },
    removeDigit() {
      this.$emit('update:modelValue', this.modelValue.slice(0, -1));
    }
  }
};
</script>

<template>
  <div class="ui-order-item bg-white rounded-lg">
    <div class="flex px-2 pt-2 pb-2 border-b">
      <div class="w-full flex items-center justify-between font-semibold whitespace-nowrap">
        <div class="flex items-center">
          <div>
            <router-link :to="`/trade-swap/${order.exchange.id}/${order.symbol}`" class="relative flex items-center">
              <CryptoIcon :symbol="order.symbol" />
              <img src="../../../node_modules/cryptocurrency-icons/svg/color/usdt.svg" class="w-5 relative right-2">
              <span>{{ order.symbol }}</span>
            </router-link>
          </div>
          <div class="pl-3">
            <template v-if="order.position === 'long'">
              <div class="bg-green-500 text-white text-sm font-normal px-2 rounded-lg">LONG</div>
            </template>
            <template v-else>
              <div class="bg-red-500 text-white text-sm font-normal px-2 rounded-lg">SHORT</div>
            </template>
          </div>
        </div>
        <div v-if="is_edit === true" class="flex items-center font-normal text-sm">
          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton class="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                <span class="sr-only">Open options</span>
                <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a @click="closePosition(order)" href="javascript:void(0)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                      {{ $t('close_position') }}
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-4 px-2 pt-2 pb-2">
      <div class="whitespace-nowrap">
        <div class="text-gray-400 text-xs">{{ $t('in_position') }}</div>
        <TheTooltip :id="`o_${order.id}`" :message="`Margin ${order.amount_margin} USDT`">
          <div class="text-sm">{{ order.amount }}</div>
        </TheTooltip>
      </div>
      <div class="whitespace-nowrap">
        <div class="text-gray-400 text-xs">{{ $t('size') }}</div>
        <div class="text-sm">{{ order.orig_qty }}</div>
      </div>
      <div class="whitespace-nowrap">
        <div class="text-gray-400 text-xs">{{ $t('leverage') }}</div>
        <div class="text-sm">x{{ parseInt(order.leverage) }}</div>
      </div>
      <div class="whitespace-nowrap">
        <div class="text-gray-400 text-xs">PnL</div>
        <div :class="[parseFloat(order.pnl) > 0 ? 'text-emerald-500' : 'text-red-500', 'text-sm']">
          <template v-if="parseFloat(order.pnl) > 0">
            +{{ roundAmount(order.pnl) }}
          </template>
          <template v-else>
            {{ roundAmount(order.pnl) }}
          </template>
        </div>
      </div>
    </div>
    <Disclosure as="div" class="w-full px-2 py-1 text-sm border-t" v-slot="{ open }">
      <dt class="flex justify-between items-center w-full">
        <div class="flex items-center">
          <img :src="`/img/${order.exchange.exchange_slug}.png`" class="w-4 rounded-full mr-1">
          {{ capitalizeFirstLetter(order.exchange.exchange_slug) }}
        </div>
        <DisclosureButton class="flex items-start text-gray-400 text-left">
          <span class="text-sm">{{ $t('detail') }}</span>
          <span class="ml-1 flex h-5 items-center">
            <PlusSmallIcon v-if="!open" class="h-5 w-5" aria-hidden="true" />
            <MinusSmallIcon v-else class="h-5 w-5" aria-hidden="true" />
          </span>
        </DisclosureButton>
      </dt>
      <DisclosurePanel as="dd" class="w-full pt-4">
        <div class="grid grid-cols-2">
          <div>
            <div class="text-xs text-gray-400">{{ $t('created_at') }}</div>
            <div class="text-sm">{{ order.created_at_format }}</div>
          </div>
          <div>
            <div class="text-xs text-gray-400">{{ $t('closing_at') }}</div>
            <div class="text-sm">{{ order.closing_date_format || '-' }}</div>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>
<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {EllipsisVerticalIcon} from "@heroicons/vue/20/solid";
import CryptoIcon from "@/components/icons/CryptoIcon.vue";
import TheTooltip from "@/components/elem/TheTooltip.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'
import {capitalizeFirstLetter} from "@/helpers/stringHelper"
import {roundAmount} from "@/helpers/numberHelper"

export default {
  props: [
    'order',
    'is_edit'
  ],

  components: {
    EllipsisVerticalIcon,
    CryptoIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TheTooltip,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MinusSmallIcon,
    PlusSmallIcon
  },

  methods: {
    closePosition(order) {
      console.log('Load orders...');

      this.$api.post(`/orders/${order.id}/close`).then((result) => {
        console.log(result.data)
        this.$emit('close-position', 1);
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    }
  },

  data() {
    return {
      capitalizeFirstLetter,
      roundAmount
    }
  },
};
</script>
<template>
  <div class="fixed z-20 w-full bottom-0 left-0 border-t px-2 py-2 bg-white">
    <nav class="grid text-sm grid-cols-5 gap-1">
      <router-link
          to="/"
          class="relative flex flex-col items-center rounded-lg py-1"
          :class="[
            $route.name === 'home' ? 'bg-gray-100 text-blue-600' : '',
            appStatus === 'PLAN_NOT_SELECTED' ? 'opacity-30 pointer-events-none' : ''
          ]"
      >
        <template v-if="appStatus === 'PLAN_NOT_SELECTED'">
          <LockClosedIcon class="w-5" />
        </template>
        <template v-else>
          <HomeIcon class="w-5" />
        </template>
        <span>{{ $t('home') }}</span>
      </router-link>
      <router-link
          to="/orders"
          class="flex flex-col items-center rounded-lg py-1"
          :class="[
            $route.name === 'orders' ? 'bg-gray-100 text-blue-600' : '',
            appStatus === 'PLAN_NOT_SELECTED' ? 'opacity-30 pointer-events-none' : ''
          ]"
      >
        <template v-if="appStatus === 'PLAN_NOT_SELECTED'">
          <LockClosedIcon class="w-5" />
        </template>
        <template v-else>
          <ArrowTrendingUpIcon class="w-5" />
        </template>
        <span>{{ $t('orders') }}</span>
      </router-link>
      <router-link
          to="/stats"
          class="flex flex-col items-center rounded-lg py-1"
          :class="[
            $route.name === 'stats' ? 'bg-gray-100 text-blue-600' : '',
            appStatus === 'PLAN_NOT_SELECTED' ? 'opacity-30 pointer-events-none' : ''
          ]"
      >
        <template v-if="appStatus === 'PLAN_NOT_SELECTED'">
          <LockClosedIcon class="w-5" />
        </template>
        <template v-else>
          <ChartPieIcon class="w-5" />
        </template>
        <span>{{ $t('stats') }}</span>
      </router-link>
      <router-link
          to="/refs"
          class="flex flex-col items-center rounded-lg py-1"
          :class="[$route.name === 'refs' ? 'bg-gray-100 text-blue-600' : '']"
      >
        <UsersIcon class="w-5" />
        <span>{{ $t('refs') }}</span>
      </router-link>

      <Popover class="relative">
        <PopoverButton
            class="w-full flex flex-col items-center rounded-lg py-1 focus:text-blue-600 focus:bg-gray-100 focus:outline-none"
        >
          <EllipsisHorizontalCircleIcon class="w-5" />
          <span>{{ $t('more') }}</span>
        </PopoverButton>

        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1/2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1/2"
        >
          <PopoverPanel
              class="absolute bottom-full right-0 z-10 mb-5 flex w-screen max-w-max pl-4"
          >
            <div class="w-screen max-w-sm flex-auto rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
              <div class="grid grid-cols-2 gap-1 p-3">
                <template v-if="$settings.screener">
                  <router-link to="/setting" class="pointer-events-none opacity-80 group relative flex items-center gap-3 rounded-lg p-2 hover:bg-gray-50">
                    <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-green-50">
                      <ArrowTrendingUpIcon class="h-6 w-6 text-green-600" />
                    </div>
                    <div>
                      <div class="font-semibold text-sm text-gray-900">
                        {{ $t('screener') }} <span class="text-xs text-white bg-red-500 px-1 rounded-xl">{{ $t('soon') }}...</span>
                        <span class="absolute inset-0" />
                      </div>
                    </div>
                  </router-link>
                </template>
                <router-link to="/tariff" class="group relative flex items-center gap-3 rounded-lg p-2 hover:bg-gray-50">
                  <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-blue-50">
                    <CurrencyDollarIcon class="h-6 w-6 text-blue-600" />
                  </div>
                  <div>
                    <div class="font-semibold text-sm text-gray-900">
                      {{ $t('tariff') }}
                      <span class="absolute inset-0" />
                    </div>
                  </div>
                </router-link>
                <router-link to="/setting" class="group relative flex items-center gap-3 rounded-lg p-2 hover:bg-gray-50">
                  <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-blue-50">
                    <Cog6ToothIcon class="h-6 w-6 text-blue-600" />
                  </div>
                  <div>
                    <div class="font-semibold text-sm text-gray-900">
                      {{ $t('settings') }}
                      <span class="absolute inset-0" />
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </nav>
  </div>
</template>
<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import {
  ArrowTrendingUpIcon,
  CurrencyDollarIcon,
  ChartPieIcon,
  UsersIcon,
  HomeIcon,
  EllipsisHorizontalCircleIcon,
  Cog6ToothIcon,
} from '@heroicons/vue/24/outline'

import {LockClosedIcon} from "@heroicons/vue/20/solid";

export default {
  components: {
    ArrowTrendingUpIcon,
    CurrencyDollarIcon,
    ChartPieIcon,
    UsersIcon,
    HomeIcon,
    LockClosedIcon,
    EllipsisHorizontalCircleIcon,
    Cog6ToothIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
  },

  computed: {
    appStatus() {
      return this.$user.app_status;
    }
  },

  mounted() {
    console.log('settings', this.$settings)
  }
}
</script>
<template>
  <div class="w-full">
    <label for="phone-input" class="block text-sm font-medium leading-6 text-gray-900">
      {{ t('phone') }}
    </label>
    <div class="mt-2 relative">
      <input
          type="tel"
          id="phone-input"
          ref="phoneInput"
          class="block w-full rounded-xl border border-gray-300 py-2 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600"
          :placeholder="t('enter_phone')"
          @input="validatePhone"
      />
      <CheckCircleIcon v-if="isValidPhone" class="absolute top-2.5 right-2 w-5 h-5 text-green-500" />
    </div>
    <div v-if="errorMessage" class="text-red-600 text-sm mt-2">{{ errorMessage }}</div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue'
import axios from 'axios'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { useI18n } from 'vue-i18n'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

const emit = defineEmits(['update:modelValue'])
const phoneInput = ref(null)
const errorMessage = ref('')
const isValidPhone = ref(false)
let iti = null

const { t } = useI18n()

onMounted(() => {
  iti = intlTelInput(phoneInput.value, {
    initialCountry: 'auto',
    geoIpLookup: function (callback) {
      axios.get('https://ipinfo.io?token=b0f135e3aa6709')
          .then(resp => {
            const countryCode = resp.data.country
            callback(countryCode)
          })
          .catch(() => {
            callback('us')
          })
    },
    utilsScript: '/utils/telUtils.js',
    separateDialCode: true,
    nationalMode: false,
  })
})

// Функция для динамической проверки
const validatePhone = () => {
  if (iti.isValidNumber()) {
    emit('update:modelValue', iti.getNumber())
    errorMessage.value = ''
    isValidPhone.value = true
  } else {
    errorMessage.value = t('invalid_phone')
    isValidPhone.value = false
  }
}
</script>
